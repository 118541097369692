<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-list"></i>
          الاقسام (الفروع) |
          <button class="btn btn-success btn-sm" @click="addNew()">
            <i class="fa fa-plus"></i> اضافة قسم
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>العنوان</th>
              <th>حساب التحضير الذكي</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="section in sections" :key="section._id">
                <td>
                  {{ section.title }}
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="section.tahdir_user_id"
                    @change="edit(section._id, section.tahdir_user_id)"
                  />
                </td>
                <td>
                  <button
                    @click="deleteAdmin(section._id)"
                    class="btn btn-relief-danger btn-sm"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  created() {
    if (checkPer(this, "admins", "view")) {
      var g = this;
      $.post(api + "/admin/sections/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.sections = JSON.parse(r).response;
      });
    }
  },
  data() {
    return {
      sections: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    edit(id, value) {
      var g = this;
      $.post(api + "/admin/sections/user_id", {
        jwt: g.user.jwt,
        id: id,
        vv: value,
      }).then(function (r) {
        $.post(api + "/admin/sections/list", {
          jwt: g.user.jwt,
        }).then(function (r) {
          g.sections = JSON.parse(r).response;
        });
      });
    },
    addNew() {
      var g = this;
      var t = prompt("العنوان:", "");
      if (t != null && t != "") {
        $.post(api + "/admin/sections/add", {
          jwt: g.user.jwt,
          title: t,
        }).then(function (r) {
          $.post(api + "/admin/sections/list", {
            jwt: g.user.jwt,
          }).then(function (r) {
            g.sections = JSON.parse(r).response;
          });
        });
      }
    },
    deleteAdmin(id) {
      if (checkPer(this, "admins", "delete")) {
        var g = this;
        if (confirm("متأكد من حذف القسم؟")) {
          $.post(api + "/admin/sections/delete", {
            jwt: g.user.jwt,
            id: id,
          }).then(function (r) {
            alert("تم الحذف");
            $.post(api + "/admin/sections/list", {
              jwt: g.user.jwt,
            }).then(function (r) {
              g.sections = JSON.parse(r).response;
            });
          });
        }
      }
    },
  },
};
</script>

<style>
</style>